<template>
	<div>
		<Navbar />
		<Header :title="'Hi ' + this.$store.state.user.name.first + `,`" excerpt="Here are some stats about your account.">
			<HeaderButton title="Create" excerpt="You can create new campaigns" route="/campaigns/create" />
		</Header>

		<!-- PAGE CONTENT -->
		<b-container>
			<b-row>
				<b-col md="4">
					<b-card class="h-100">
						<b-card-body class="credit-card">
							
							<p class="title">BALANCE</p>
							<h1>Rs: {{ this.$store.state.user.balance }}</h1>
							<p class="appx"><i>You'll be able send approximately {{ Math.floor(this.$store.state.user.balance / this.$store.state.user.package.cost.sms) }} sms</i>.</p>
							<br><br>
							<b-button class="px-5" variant="primary">Recharge</b-button>

						</b-card-body>
					</b-card>
				</b-col>

				<!-- CHART -->
				<b-col md="8">
					<b-card class="h-100">
						<div class="b-card-body">

						</div>
					</b-card>
				</b-col>

			</b-row>
		</b-container>

	</div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import HeaderButton from "@/components/Header/Button";

export default {
	name: "Home",
	components: { Header, Navbar, HeaderButton },

	mounted() {
		this.$store.dispatch("user/initialize")
	}

}
</script>

<style lang="scss" scope>
.credit-card {
	text-align: center;

	.title {
		font-size: 15px;
		font-weight: 600;
		color: $text-dark-secondary;
		letter-spacing: 3px;
	}

	h1 {
		margin-top: 50px;
		font-size: 35px !important;
		font-weight: 400 !important;
		color: $text-primary;
	}

	.appx {
		margin-top: 20px;
		font-weight: 600;
		font-size: 14px;
		color: $text-secondary;
	}
}
</style>
