<template>
	<div>
		<b-container fluid>
			<b-row>
				<b-col md="4" class="login">
					<img src="/images/logo-real.png" alt="Zend Logo" class="logo">
					<p class="tag-line">
						Log into Zend and harness the power of <br> digital telecommunication.
					</p>

					<b-form class="my-5">
						<div class="form-group">
							<label for="email">Email Address</label>
							<b-form-input v-model="email" type="text" class="form-control input-lg" :state="validation.email"></b-form-input>
							<b-form-invalid-feedback :state="validation.email">{{ errors.email }}</b-form-invalid-feedback>
						</div>
						<div class="form-group">
							<label for="email">Password</label>
							<b-form-input v-model="password" type="password" class="form-control input-lg" :state="validation.password"></b-form-input>
							<b-form-invalid-feedback :state="validation.password">{{ errors.password }}</b-form-invalid-feedback>
						</div>
					</b-form>

					<b-button v-on:click="login()" variant="primary" block>Continue</b-button>

				</b-col>

				<b-col md="8" class="slider">
					<img src="@/assets/images/login-bg.svg" class="img-fluid">
					<h3>Create campaigns to send bulk messages.</h3>
					<p>
						Time is critical in business. We know you don’t have time to send the <br> same message to people one by one. Send bulk <br> messages and save time.
					</p>
				</b-col>

			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: "login",

	data: () => ({
		email: '',
		password: '',
		validation: {
			email: null,
			password: null
		},
		errors: {
			email: null,
			password: null
		}
	}),

	methods: {
		login() {

			/**
			 * We have to disptach vuex action with the email and password
			 * details for make authentication request and then we can act
			 * based on the return data.
			 */
			this.$store.dispatch("user/login", {
				email: this.email,
				password: this.password
			}).then(() => {
				this.$router.push("/home")
			}).catch(Response => {
				if (Response.status == "failed") {

					this.errors.email = null
					this.errors.password = null
					this.validation.email = null
					this.validation.password = null

					Object.entries(Response.errors.fields).forEach(element => {
						this.validation[element[0]] = false
						this.errors[element[0]] = element[1]
					})
				}
			})

		}
	}
}
</script>

<style lang="scss" scoped>
.login, .slider {
	height: 100vh;
}

.login {
	padding-left: 100px;
	padding-right: 100px;

	.logo {
		margin-top: 150px;
		margin-bottom: 25px;
	}

	p.tag-line {
		font-weight: 500;
		font-size: 15px;
		line-height: 1.8;
		color: $text-dark-secondary;
	}

	.form-group {
		label {
			font-weight: 600;
			font-size: 14px;
			color: $text-dark;
		}

		input {
			height: 45px;
			font-size: 14px;
			font-weight: 500;
		}
	}

	.btn {
		font-size: 14px;
		font-weight: 600 !important;
		padding-top:10px;
		padding-bottom:10px;
	}

}

.slider {
	background: #000D71;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		max-width: 700px;
	}

	h3 {
		margin-top: 100px;
		margin-bottom: 50px;
		color: white;
		font-size: 18px;
		font-weight: 600;
	}

	p {
		text-align: center;
		font-size: 15px;
		font-weight: 500;
		color: #B7BFF9;
	}

}
</style>
